import Service from "../Service";

const resource = "operationcommercial/";

export default {
    listoc(parameters, requestID) {
        return Service.post(resource + "listoc", parameters, {
            params: { requestID: requestID },
        });
    },
};