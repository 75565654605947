<template>
<div>
    <v-container>

        <!-- EDICION Y CREACION DE REQUERIMIENTOS EN VENTANA MODAL  -->
        <div v-if="dialog">
            <v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
                <atc-requirement-edit :value="RequirementEdit.ReqID" @close="dialog = false" :itemOC="selectedGestion[0]" :visible="visibleCard"></atc-requirement-edit>
            </v-dialog>
        </div>

        <v-row>

            <v-col cols="12">
                <s-toolbar dark label="Gestión de Solicitudes"></s-toolbar>

                <v-card outlined>
                    <v-row justify="center" style="margin:auto;margin-top:-20px">

                        <v-col cols="4" lg="4" md="4" style="margin-bottom:-10px">

                            <v-radio-group v-model="radios" column>
                                <template v-slot:label>
                                    <div>Seleccione un metodo de generación</div>
                                </template>
                                <v-radio :value="1" color="red">
                                    <template v-slot:label>
                                        <div><strong class="error--text">Relacionar con Operacion Comercial</strong></div>
                                    </template>
                                </v-radio>
                                <v-radio :value="2" style="margin-top: -10px" color="red">
                                    <template v-slot:label>
                                        <div><strong class="primary--text">Sin Relacionar</strong></div>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-col>

                        <v-col cols="8" lg="8" md="8" style="margin-bottom:-20px">

                            <v-row style="margin:auto">
                                <v-col cols="12">
                                    <v-card class="mx-auto my-1" width="100%">

                                        <v-alert v-model="alert" style="border-radius: 10px 10px 10px 10px; overflow: auto; z-index: 9999999; width: 100%; right: 0px; top: 5%;" border="right" color="error" dark dense dismissible shaped close-icon="fas fa-times-circle">
                                            <span style="font-family: Calibri; font-size: 14px">No se encontraron registros para la busqueda realizada</span>
                                        </v-alert>
                                    </v-card>
                                    <v-card outlined class="mx-auto my-1 pb-3" :disabled="!visibleCard">
                                        <v-row justify="center" style="margin-top: 0px; margin-left:0px;">
                                            <v-col cols="8" lg="7" md="7">
                                                <s-text ref="txtDocIdentidad" v-model="txtDocIdentidad" label="Buscar Por: [Dni, Nombres, Nro. Lote, Cod. Venta]" @keyupEnter="btnSearch()"></s-text>
                                            </v-col>
                                            <v-col cols="2" lg="3" md="3">
                                                <v-btn elevation="0" class="mt-3" style="margin-left:-20px" small color="info" @click="btnSearch()">
                                                    Buscar
                                                </v-btn>
                                            </v-col>

                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>

                </v-card>

                <v-card class="mt-2" outlined>

                    <v-row style="margin:auto">
                        <v-col cols="12" v-if="visibleCard  && items.length > 0">
                            <!-- <div><b><u>Operaciones Comerciales</u></b></div> -->
                            <div style="margin-top:-20px" class="mb-3"><h3>Operaciones Comerciales</h3></div>
                            <v-card outlined class="mx-auto my-1 pb-2" width="100%">
                                <v-data-table disable-sort dense disable-filtering hide-default-footer :items-per-page="-1" height="150" :items="items" :headers="headers" v-model="selectedGestion" @click:row="rowClick" item-key="CodSales">
                                    <template v-slot:item.EstateOC="{ item }">
                                        <v-chip x-small :color="item.EstateOC == 'CV' ? 'success' : 'error'">
                                            {{item.EstateOC}}
                                        </v-chip>
                                        
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card class="mt-2" outlined>

                    <v-row style="margin:auto">
                        <v-col cols="12">
                            <div style="margin-top:-20px" class="mb-3"><h3>Historial</h3></div>
                            <v-card outlined class="mx-auto my-1 pb-2" width="100%">
                                <s-crud noFull height="200" ref="grid" search-input="" add-no-function :filter="filter" :view="selected.length > 0 ? true : false" @add="add()" :add="selectedGestion.length > 0 || !visibleCard ? true : false" @edit="edit()" :config="config" @rowSelected="rowSelected($event)">
                                    <template v-slot:filter>
                                        <v-container>
                                            <v-container>
                                                <v-row justify="center">
                                                    <v-col lg="3" md="3" cols="6" class="s-col-form">
                                                        <s-select-definition clearable label="Estados" noDefault v-model="filter.ReqStatus" :def="1187" />
                                                    </v-col>
                                                    <v-col lg="3" md="3" cols="6" class="s-col-form" v-if="$fun.isAdmin()">
                                                        <c-select-area full clearable noDefault v-model="filter.AreID" label="Área" />
                                                    </v-col>
                                                    <v-col lg="2" md="3" cols="6" class="s-col-form">
                                                        <s-date v-model="filter.BeginDate" label="Fecha Inicio" />
                                                    </v-col>
                                                    <v-col lg="2" md="3" cols="6" class="s-col-form">
                                                        <s-date v-model="filter.EndDate" label="Fecha Fin" />
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-container>
                                    </template>
                                    <template v-slot:ReqStatusName="{ row }">
                                        <v-chip x-small :color="row.ReqStatusColor">
                                            {{ row.ReqStatusName }}<span>
                                                <v-btn style="margin-left:10px" icon x-small elevation="0" dark @click="dialogApprovalRequirement= true"><i class="fas fa-eye"></i></v-btn>
                                            </span>
                                        </v-chip>

                                    </template>

                                    <template v-slot:TypePriorityName="{ row }">
                                        <v-chip x-small :color="row.TypePriorityColor">
                                            {{ row.TypePriorityName }}
                                        </v-chip>
                                    </template>
                                </s-crud>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <!-- NIVELES DE APROBACION DE REQ-->
        <v-dialog v-model="dialogApprovalRequirement" v-if="dialogApprovalRequirement" width="60%" style="display: inline-table;">
            <ApprovalLevelsDocument :DocEntry="ReqID" :TypeObject="TrsID" />
        </v-dialog>
    </v-container>

</div>
</template>

<script>
//Services
import _sRequirement from "@/services/CustomerSupport/AtcRequirementService";
import _sOC from "@/services/CustomerSupport/AtcOperationCommercial";
import _sTransaction from '@/services/Security/ApprovalTransactionService'

import AtcRequirementEdit from "@/views/CustomerSupport/Requirement/AtcRequirementEdit";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";

export default {

    components: {
        AtcRequirementEdit,
        ApprovalLevelsDocument
    },
    data() {
        return {

            processing: false,
            messageProcessing: "",
            alert: false,
            radios: 1,
            visibleCard: false,

            items: [],
            headers: [{
                    text: "Cod. Venta",
                    value: "CodSales"
                },
                {
                    text: "Proyecto",
                    value: "ProjectName"
                },
                {
                    text: "Nro. Lote",
                    value: "NroLot"
                },
                {
                    text: "Doc. Identidad",
                    value: "DocCustomer"
                },
                {
                    text: "Cliente",
                    value: "CustomerName"
                },
                {
                    text: "Direccion",
                    value: "CustomerAddress"
                },
                {
                    text: "Celular",
                    value: "CustomerCel"
                },
                {
                    text: "Estado",
                    value: "EstateOC"
                },
            ],

            filter: {
                CodSales: null,
                ReqStatus: 4
                
            },
            txtDocIdentidad: "",

            dialogApprovalRequirement: false,

            DocEntry: null,
            ReqID: null,
            TrsID: null,
            RequirementEdit: {},
            dialog: false,
            selected: [],
            selectedGestion: [],

            config: {
                model: {
                    ReqDocumentNumber: "ID",
                    ReqStatusName: "",
                    ReqDate: "datetime",
                    SecUpdate: "datetime",
                    TypePriorityName: "string",
                    MailOK: "string",
                    ReqDay: ""
                },
                service: _sRequirement,
                headers: [{
                        text: "ID",
                        value: "ReqID",
                        sortable: false,
                        width: 40
                    },

                    {
                        text: "N° Solicitud",
                        value: "ReqDocumentNumber",
                        sortable: false,
                        width: 100,
                    },

                    {
                        text: "Tipo Solicitud",
                        value: "TrsName",
                        sortable: false,
                        width: 100,
                    },
                    {
                        text: "Medio",
                        value: "TypeMedioName",
                        sortable: false,
                        width: 100,
                    },

                    
                    {
                        text: "Solicitante",
                        value: "UsrName",
                        sortable: false,
                        width: 200,
                        

                    },
                    {
                        text: "Estado",
                        value: "ReqStatusName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Estado Proceso",
                        value: "AlvProcessName",
                        sortable: false,
                        width: 150,
                        align: "center",
                    },
                    
                    {
                        text: "Prioridad",
                        value: "TypePriorityName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Fecha Inicio",
                        value: "ReqDate",
                        sortable: false,
                        width: 110
                    },
                    {
                        text: "Fecha Fin",
                        value: "SecUpdate",
                        sortable: false,
                        width: 140
                    },
                    {
                        text: "Tiemp. Proceso",
                        value: "ReqDay",
                        sortable: false,
                        width: 40,
                        align: "center",
                    },

                    
                    {
                        text: "Área",
                        value: "AreName",
                        sortable: false,
                        width: 100,
                    },
                    {
                        text: "Ok",
                        value: "MailOK",
                        sortable: false,
                        width: 50,
                    },

                ],
            },
        };
    },

    watch: {
        radios() {
            this.show()
            if(this.radios == 1){
                this.filter.CodSales = "000000000"
            }else{
                this.filter.CodSales= 0
            }
        },

        dialog() {
            this.$refs.grid.refresh();
        },
    },

    created() {
        this.show()
    },
    methods: {

        show() {
            if (this.radios == 1) {
                this.visibleCard = true
                this.selectedGestion = []
                //this.itemsGestion = []
                //this.items = []
            } else {
                this.visibleCard = false
                this.selectedGestion = []
                //this.itemsGestion = []
                //this.items = []
            }
        },

        rowSelected(item) {
            this.selected = item;
            if (item != null) {
                if (item.length > 0) {
                    if (item[0].ReqID != null)
                        this.ReqID = item[0].ReqID
                    this.TrsID = item[0].TrsID
                }
            }
        },

        rowClick: function (item, row) {
            this.selectedGestion = [item];

            if (this.selectedGestion.length > 0) {
                this.filter.CodSales = this.selectedGestion[0].CodSales
            }
        },

        btnSearch() {
            this.selectedGestion = []
            this.filter.CodSales = null

            if(this.txtDocIdentidad !== null && this.txtDocIdentidad.length > 0){
                _sOC.listoc({
                DocCustomer: this.txtDocIdentidad
            }, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {
                    //console.log("respuesta", resp.data)
                    if (resp.data.length == 0) {
                        this.alert = true
                    } else {
                        this.alert = false
                    }
                    this.items = resp.data
                }
            })
            }
            
        },

        add() {

            this.RequirementEdit.ReqID = null;
            this.dialog = true;
        },

        //Editar Requerimiento
        edit() {
            this.RequirementEdit = this.selected.length > 0 ? this.selected[0] : null;
            this.dialog = true;
        },

    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
